











































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'uOttawa1321Prelab3Q1',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswer: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {text: 'Dichloromethane/water', value: 'dichloromethane'},
        {text: 'Ethanol/water', value: 'ethanol'},
        {text: 'Hexanes/water', value: 'hexanes'},
        {text: 'Ether/water', value: 'ether'},
        {text: 'Ethyl acetate/water', value: 'ethylAcetate'},
      ],
      optionsFr: [
        {text: 'Dichlorométhane/eau', value: 'dichloromethaneFr'},
        {text: 'Éthanol/eau', value: 'ethanolFr'},
        {text: 'Éther/eau', value: 'etherFr'},
        {text: "Acétate d'éthyle/eau", value: 'ethylAcetateFr'},
        {text: 'Hexanes/eau', value: 'hexanesFr'},
      ],
    };
  },
};
